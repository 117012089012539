import styled from 'styled-components';
import StepWizard from 'react-step-wizard';

export const BottomNavWizard = styled(StepWizard)`
    display: flex;
    flex-direction: column-reverse;

    nav {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }
`;
