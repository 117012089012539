import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyCHu4iJaeoQfNF4mSmL-I_QiLwCEVWDrvs',
    authDomain: 'iou-app-cf84e.firebaseapp.com',
    databaseURL: 'https://iou-app-cf84e.firebaseio.com',
    projectId: 'iou-app-cf84e',
    storageBucket: 'iou-app-cf84e.appspot.com',
    messagingSenderId: '850769608519',
    appId: '1:850769608519:web:2042f0eefd421486',
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

export { firebase, firestore };
