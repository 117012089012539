// @flow

import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, populate, WithFirebaseProps } from 'react-redux-firebase';
import { AppState } from 'config/reducer';
import { Profile, PolishedDatabaseDebt } from '../types/database';

const collection = 'debts';

const getPopulationCriteria = (filter: 'IO' | 'UO') => [
    { child: filter === 'UO' ? 'debtor' : 'creditor', root: 'users' },
];

const sumValues = (debts: PolishedDatabaseDebt[], label?: 'R' | 'O' | 'G' | 'B') =>
    debts
        .filter(({ labels }) => (label ? labels && labels.indexOf(label) !== -1 : true))
        .reduce(
            (total, { value, returned }) =>
                total +
                value -
                (returned ? returned.reduce((a, returnSession) => a + returnSession.value, 0) : 0),
            0,
        );

export default (filter: 'IO' | 'UO') => {
    const populationCriteria = getPopulationCriteria(filter);
    const mapStateToProps = ({ firebase, firestore }: AppState) => {
        const populatedDebts = populate(firestore, collection, populationCriteria);

        const debts = populatedDebts
            ? Object.entries<PolishedDatabaseDebt>(populatedDebts)
                  .map(debt => ({
                      id: debt[0],
                      ...debt[1],
                      timestamp: debt[1].timestamp && moment(debt[1].timestamp.toDate()),
                  }))
                  // TODO: this can be done way better
                  .filter(debt =>
                      filter === 'UO'
                          ? debt.creditor === firebase.auth.uid
                          : debt.debtor === firebase.auth.uid,
                  )
            : [];

        return {
            hero: {
                total: sumValues(debts),
                R: sumValues(debts, 'R'),
                O: sumValues(debts, 'O'),
                G: sumValues(debts, 'G'),
                B: sumValues(debts, 'B'),
            },
            users: firestore.data.users,
            debts,
        };
    };

    return compose(
        connect(mapStateToProps),
        firestoreConnect(({ firebase: { auth } }: WithFirebaseProps<Profile>) => {
            const { currentUser } = auth();
            if (!currentUser) return [];
            return [
                {
                    collection,
                    populationCriteria,
                    where: ['owner', '==', currentUser.uid],
                },
                {
                    collection: 'users',
                },
            ];
        }),
    );
};
