import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from 'config/reducer';
import { Layout, Form, Sheet } from './style';

interface StateProps {
    auth: {
        isLoaded: boolean;
        isEmpty: boolean;
    };
}

interface OwnProps {
    children: JSX.Element;
}

type Props = StateProps & OwnProps;

const AuthLayout = ({ auth, children }: Props) => {
    const { isLoaded, isEmpty } = auth;

    const ShowOrRedirect = () =>
        isEmpty ? (
            <Layout>
                <Sheet>
                    <Form>{children}</Form>
                </Sheet>
            </Layout>
        ) : (
            <Redirect to="/" />
        );

    return isLoaded ? <ShowOrRedirect /> : null;
};

const mapStateToProps = (state: AppState): StateProps => ({
    auth: state.firebase.auth,
});

const ConnectedAuthLayout = connect(mapStateToProps)(AuthLayout);

export default ConnectedAuthLayout;
