// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withFirebase, WithFirebaseProps } from 'react-redux-firebase';
import { Container, Grid, Button } from '@material-ui/core';
import ProfileCard from 'components/ProfileCard';
import { AppState } from 'config/reducer';
import { User } from 'types/database';
import './ProfileCard.scss';

const Settings = ({
    profile,
    firebase: { logout },
}: WithFirebaseProps<User> & { profile: User }) => {
    return (
        <Container>
            <Grid container justify="center">
                <Grid item xs={12} md={6}>
                    <div className="Settings">
                        <ProfileCard {...profile} />
                        <Button onClick={logout}>Logout</Button>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state: AppState) => ({
    profile: state.firebase.profile,
});

export default connect(mapStateToProps)(withFirebase(Settings));
