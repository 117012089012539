import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import DebtsView from 'components/DebtsView/DebtsView';
import composeDebtData from 'helpers/composeDebtData';
import { PolishedDatabaseDebt } from 'types/database';
import './Home.scss';
import { RouteChildrenProps } from 'react-router';

const HomeComponent = ({
    hero,
    users,
    debts,
    ...rest
}: RouteChildrenProps<{
    id: string;
}> & {
    hero: {
        total: number;
        R: number;
        O: number;
        G: number;
        B: number;
    };
    debts: PolishedDatabaseDebt[];
    users: {}[];
}) => {
    const [activeDebt, setActiveDebt] = useState<PolishedDatabaseDebt | null>(null);
    const [activeTab, setActiveTab] = useState(0);
    const filterConditions = [
        (debt: PolishedDatabaseDebt) =>
            !debt.returned ||
            debt.returned.reduce((total, returnSession) => total + returnSession.value, 0) <
                debt.value,
        (debt: PolishedDatabaseDebt) =>
            debt.returned &&
            debt.returned.reduce((total, returnSession) => total + returnSession.value, 0) ===
                debt.value,
        () => true,
    ];

    return (
        <DebtsView
            {...{
                hero,
                activeTab,
                activeDebt,
                debts,
                users,
                filterConditions,
                setActiveTab,
                setActiveDebt,
                ...rest,
            }}
        />
    );
};

export default composeDebtData('IO')(HomeComponent);
