import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Layout = styled.div`
    height: 100vh;
    display: flex;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Sheet = styled(Paper)`
    position: fixed;
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 24px;

    h3 {
        text-align: center;
        margin-bottom: 24px;
    }

    h4 {
        margin-bottom: 20px;
    }

    /* a,
    button {
        margin-top: 16px;
        color: ${({ theme }) => theme.palette.primary.main};
    } */
`;

export const Form = styled.div`
    width: 100%;

    h2 {
        margin-bottom: 32px;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    /* a,
    button {
        // TODO: move to theme
        height: 48px;
    } */
`;
