import React from 'react';
import { Grid, AppBar, Toolbar } from '@material-ui/core';
import Logo from 'assets/icons/logo.svg';
import './Navbar.scss';

const Navbar = () => {
    return (
        <AppBar position="static" className="Navbar">
            <Toolbar className="Navbar__toolbar">
                <Grid container>
                    <Grid className="Navbar__cell Navbar__left" item xs={5} />
                    <Grid className="Navbar__cell Navbar__center" item xs={2}>
                        <img src={Logo} alt="logo" />
                    </Grid>
                    <Grid className="Navbar__cell Navbar__right" item xs={5}>
                        {/* Reinstate when the logic is there */}
                        {/* <IconButton>
                            <img src={FilterIcon} />
                        </IconButton>
                        <IconButton>
                            <img src={SortIcon} />
                        </IconButton> */}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
