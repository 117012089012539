// @flow

import React from 'react';
import classNames from 'classnames';
import Value from 'components/Value/Value';
import './CategoryLabel.scss';

interface Props {
    variant: string;
    text: string;
    noText: boolean;
}

const CategoryLabel = ({ variant, text, noText }: Props) => {
    return (
        <div
            className={classNames('CategoryLabel', {
                'CategoryLabel--tiny': noText,
            })}
        >
            <div
                className={classNames('CategoryLabel__bullet', `CategoryLabel__bullet--${variant}`)}
            />
            {noText || <Value value={text} currency="EUR" isTiny />}
        </div>
    );
};

CategoryLabel.defaultProps = {
    text: '',
    noText: false,
};

export default CategoryLabel;
