// @flow

import React from 'react';
import { RouteProps } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Value from 'components/Value/Value';
import CategoryLabel from 'components/CategoryLabel/CategoryLabel';
import PlainTabs from 'components/PlainTabs';
import PlainTab from 'components/PlainTab';
import DebtCard from 'components/DebtCard/DebtCard';
import DebtViewer from 'components/DebtViewer/DebtViewer';
import { PolishedDatabaseDebt } from 'types/database';
import './DebtsView.scss';
import { StickyContainer, Sticky } from 'react-sticky';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import PlainLink from 'styles/PlainLink';
import DebtsViewDialog from './DebtsViewDialog';

interface Props {
    hero: {
        total: number;
        R: number;
        O: number;
        G: number;
        B: number;
    };
    activeDebt: PolishedDatabaseDebt | null;
    activeTab: number;
    debts: PolishedDatabaseDebt[];
    users: {}[];
    filterConditions: ((debt: PolishedDatabaseDebt) => boolean)[];
    setActiveTab: (value: number) => void;
    setActiveDebt: (value: PolishedDatabaseDebt | null) => void;
}

const DebtsView = ({
    hero,
    activeTab,
    // activeDebt,
    debts,
    users,
    filterConditions,
    setActiveTab,
    setActiveDebt,
    match,
    location,
    history,
}: RouteChildrenProps<{
    id: string;
}> &
    Props) => {
    const Labels = Object.entries(hero)
        .filter(([key]) => key !== 'total')
        .map(([key, value]) => <CategoryLabel key={key} variant={key} text={value.toFixed(2)} />);

    const hydratedDebts =
        debts && debts.length
            ? debts.filter(filterConditions[activeTab]).map(dataValue => {
                  const subject = dataValue.type === 'IO' ? dataValue.creditor : dataValue.debtor;
                  return {
                      ...dataValue,
                      subject: typeof subject === 'string' ? subject : subject.name,
                  };
              })
            : [];
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    const activeDebtId = match ? match.params.id : null;
    const activeDebt = hydratedDebts.filter(debt => debt.id === activeDebtId)[0];

    return (
        <section className="Home">
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} md={6}>
                        <div className="Home__hero">
                            <Value currency="EUR" value={hero.total.toFixed(2)} isBig />
                            <div className="Home__hero__labels">{Labels}</div>
                        </div>
                        <div className="Home__body">
                            <Sticky topOffset={0} relative>
                                {({ style, isSticky }) => (
                                    <div style={{ ...style, zIndex: 1000 }}>
                                        <PlainTabs
                                            value={activeTab}
                                            onChange={setActiveTab}
                                            solid={isSticky}
                                        >
                                            <PlainTab label="UNPAID" />
                                            <PlainTab label="PAID" />
                                            <PlainTab label="ALL" />
                                        </PlainTabs>
                                    </div>
                                )}
                            </Sticky>
                            {hydratedDebts.map(debt => (
                                <PlainLink key={debt.id} to={`${location.pathname}/${debt.id}`}>
                                    <DebtCard {...debt} />
                                </PlainLink>
                            ))}
                        </div>
                    </Grid>
                    {isSmallScreen ? (
                        <DebtsViewDialog isOpen={Boolean(activeDebt)} onClose={history.goBack}>
                            {activeDebt ? <DebtViewer {...activeDebt} /> : <div />}
                        </DebtsViewDialog>
                    ) : null}
                    {!isSmallScreen && activeDebt ? (
                        <Grid item xs={12} md={4}>
                            {activeDebt ? <DebtViewer {...activeDebt} /> : <div />}
                        </Grid>
                    ) : null}
                </Grid>
            </Container>
        </section>
    );
};

export default DebtsView;
