// @flow

import { PolishedDatabaseDebt } from 'types/database';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Value from 'components/Value/Value';
import SimpleDebtCard from 'components/DebtCard/SimpleDebtCard';
import './DebtViewer.scss';
import { Hero } from './styles';

const capitalizeName = (name: string): string => name.charAt(0).toUpperCase() + name.slice(1);

const DebtViewer = ({ value, type, name, debtor, creditor, returned }: PolishedDatabaseDebt) => {
    const contractor = type === 'IO' ? creditor : debtor;
    const contractorName = typeof contractor === 'string' ? contractor : contractor.name;

    return (
        <div className="DebtViewer">
            <Hero>
                <Typography variant="h6">
                    {`You ${type === 'IO' ? 'owe' : 'gave'} `}
                    <strong>{contractorName}</strong>
                </Typography>
                <Value currency="EUR" value={value.toFixed(2)} isBig />
                <Typography variant="h6">Because of:</Typography>
                <Typography>{name}</Typography>
            </Hero>
            <div className="DebtViewer__payments">
                {returned &&
                    returned.map(({ value: returnedValue, description, timestamp }) => (
                        <SimpleDebtCard
                            key={timestamp + description}
                            name={type === 'UO' ? `${contractorName} returned` : 'You returned'}
                            timestamp={timestamp}
                            value={returnedValue}
                        />
                    ))}
            </div>
        </div>
    );
};

export default DebtViewer;
