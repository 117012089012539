// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import './Dial.scss';

interface Props {
    actions: {
        icon: JSX.Element;
        name: string;
        onClick?: () => void;
        to?: string;
    }[];
}

const Dial = ({ actions }: Props) => {
    const [isOpen, toggleDial] = useState(false);

    return (
        <SpeedDial
            ariaLabel="add something"
            className="Dial"
            icon={<SpeedDialIcon />}
            onBlur={() => toggleDial(false)}
            onClick={() => toggleDial(!isOpen)}
            onClose={() => toggleDial(false)}
            onFocus={() => toggleDial(true)}
            onMouseEnter={() => toggleDial(true)}
            onMouseLeave={() => toggleDial(false)}
            open={isOpen}
            direction="up"
        >
            {actions.map(action => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    to={action.to}
                    ButtonProps={{
                        // TODO: find an actual solution
                        // @ts-ignore
                        component: Link,
                    }}
                    tooltipOpen
                />
            ))}
        </SpeedDial>
    );
};

export default Dial;
