import React, { ChangeEvent } from 'react';
import { TextField, Typography } from '@material-ui/core';

const Email = ({
    value,
    onChange,
}: {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <>
            <Typography variant="h4">What&apos;s your email?</Typography>
            <TextField
                value={value}
                onChange={onChange}
                variant="outlined"
                type="email"
                label="Email"
                fullWidth
            />
        </>
    );
};

export default Email;
