// @flow

import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Value from 'components/Value/Value';
import { getTimestampString } from './helpers';
import './DebtCard.scss';

interface Props {
    name: string;
    value: number;
    timestamp?: string | firebase.firestore.Timestamp;
}

const DebtCard = ({ name, timestamp, value }: Props) => {
    return (
        <Paper className="DebtCard DebtCard--simple">
            <div className="DebtCard__content">
                <Typography variant="h5">{name}</Typography>
                <Typography variant="h6">{getTimestampString(timestamp)}</Typography>
            </div>
            <div className="DebtCard__value">
                <Value currency="EUR" value={value.toFixed(2)} />
            </div>
        </Paper>
    );
};

export default DebtCard;
