// @flow

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import './DialogToolbar.scss';

interface Props {
    position?: 'static' | 'absolute';
    onClose: () => void;
}

const DialogToolbar = ({ position, onClose }: Props) => {
    return (
        <AppBar className="DialogToolbar" position={position}>
            <Toolbar component="nav">
                <IconButton edge="start" onClick={onClose} aria-label="Close">
                    <BackIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

DialogToolbar.defaultValues = {
    position: 'static',
};

export default DialogToolbar;
