import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { ReactComponent as IOIcon } from 'assets/icons/io.svg';
import { ReactComponent as UOIcon } from 'assets/icons/uo.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import './BottomNav.scss';

interface Props {
    value: '/' | '/io' | '/uo' | '/settings';
}

const BottomNav = ({ value }: Props) => {
    return (
        <AppBar position="static" color="inherit">
            <BottomNavigation value={value} className="BottomNav">
                <BottomNavigationAction
                    classes={{
                        label: 'BottomNav__item__label',
                        selected: 'BottomNav__item--selected',
                    }}
                    label="My debts"
                    value="/io"
                    icon={<IOIcon className="BottomNav__icon" />}
                    component={Link}
                    to="/io"
                    replace
                />
                <BottomNavigationAction
                    classes={{
                        label: 'BottomNav__item__label',
                        selected: 'BottomNav__item--selected',
                    }}
                    label="Their debts"
                    value="/uo"
                    icon={<UOIcon className="BottomNav__icon" />}
                    component={Link}
                    to="/uo"
                    replace
                />
                <BottomNavigationAction
                    classes={{
                        label: 'BottomNav__item__label',
                        selected: 'BottomNav__item--selected',
                    }}
                    label="Settings"
                    value="/settings"
                    icon={<SettingsIcon className="BottomNav__icon" />}
                    component={Link}
                    to="/settings"
                    replace
                />
            </BottomNavigation>
        </AppBar>
    );
};

export default BottomNav;
