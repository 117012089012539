// @flow

import React from 'react';
import { Slide, Dialog } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogToolbar from 'components/DialogToolbar/DialogToolbar';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const DebtsViewDialog = ({
    children,
    isOpen,
    onClose,
}: {
    children: JSX.Element;
    isOpen: boolean;
    onClose: () => void;
}) => {
    return (
        <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            <DialogToolbar onClose={onClose} position="absolute" />
            {children}
        </Dialog>
    );
};

export default DebtsViewDialog;
