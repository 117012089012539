import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import withWidth from '@material-ui/core/withWidth';
import { Slide } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import * as serviceWorker from './config/serviceWorker';
import { firebase } from './config/firebase';
import rootReducer from './config/reducer';
import getTheme from './config/theme';
import App from './App';
import './index.scss';

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true,
};

const middleware = applyMiddleware(thunk);
const store = createStore(rootReducer, {}, composeWithDevTools(middleware));

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

const TransitionUp = (props: {}) => {
    return <Slide {...props} direction="up" />;
};

const Application = ({ width }: { width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }) => (
    <StylesProvider injectFirst>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <MuiThemeProvider theme={getTheme(width)}>
                    <ThemeProvider theme={getTheme(width)}>
                        <SnackbarProvider TransitionComponent={TransitionUp}>
                            <CssBaseline />
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    </StylesProvider>
);

const EnhancedApplication = withWidth()(Application);

const root = document.getElementById('root');

if (root !== null) ReactDOM.render(<EnhancedApplication />, root);

serviceWorker.register();
