import styled from 'styled-components';
import { TextField, Container } from '@material-ui/core';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const CustomContainer = styled(Container)`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-top: 64px;
    align-items: center;
    text-align: center;
`;

export const Header = styled.div`
    position: relative;
    background: #0072ff;
    border-radius: 24px;
    padding: 40px 12px 24px 12px;
    margin-top: 32px;

    .Value {
        position: relative;
        color: white;
    }

    svg {
        position: absolute;
        top: 0;
        left: 24px;
        transform: translateY(-50%) scale(1.2);

        #secondary {
            display: none;
        }

        #neutral {
            fill: #303030;
            fill-opacity: 1;
        }
    }
`;

export const FieldGroup = styled.div`
    margin-top: 32px;
`;

export const Field = styled(TextField)`
    margin-top: 16px;

    input {
        text-align: center;
    }
`;
