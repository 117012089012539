// @flow

import React, { MouseEvent, KeyboardEvent } from 'react';
import Measure, { BoundingRect } from 'react-measure';
import './PlainTab.scss';

interface Props {
    label: string;
    isSelected: boolean;
    onClick: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
    onResize?: (contentRect: BoundingRect) => void;
}

const PlainTab = ({ label, isSelected, onClick, onResize }: Props) => {
    return (
        <Measure bounds onResize={({ bounds }) => bounds && onResize && onResize(bounds)}>
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    className={`PlainTab ${isSelected ? 'PlainTab--active' : ''}`}
                    onClick={onClick}
                    onKeyDown={onClick}
                    role="button"
                    tabIndex={0}
                >
                    <span>{label}</span>
                </div>
            )}
        </Measure>
    );
};

PlainTab.defaultProps = {
    isSelected: false,
    onClick: () => null,
};

export default PlainTab;
