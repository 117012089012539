// @flow

import React from 'react';
import moment, { Moment } from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CategoryLabel from 'components/CategoryLabel/CategoryLabel';
import AvatarGraph from 'components/AvatarGraph/AvatarGraph';
import Value from 'components/Value/Value';
import Placeholderavatar from 'assets/images/avatar.svg';
import { Returned } from 'types/database';
import './DebtCard.scss';

interface Props {
    photo?: string;
    subject: string;
    name: string;
    timestamp?: Moment;
    value: number;
    returned?: Returned[];
    labels?: ('R' | 'O' | 'G' | 'B')[];
    onClick?: () => void;
}

const DebtCard = ({ photo, subject, name, timestamp, value, returned, labels, onClick }: Props) => {
    const returnedAmount = (returned || []).reduce(
        (total, returnSession) => total + returnSession.value,
        0,
    );
    return (
        <Paper className="DebtCard" onClick={onClick}>
            <AvatarGraph className="DebtCard__avatar" total={value} value={returnedAmount}>
                <Avatar src={photo} />
            </AvatarGraph>
            <div className="DebtCard__content">
                <Typography variant="h5">{name}</Typography>
                <Typography variant="h6">{`${subject} - ${moment(timestamp).format(
                    'DD/MM/YYYY hh:mm',
                )}`}</Typography>
            </div>
            <div className="DebtCard__value">
                <Value currency="EUR" value={(value - returnedAmount).toFixed(2)} />
            </div>
            <div className="DebtCard__labels">
                {!labels ||
                    labels.map(label => <CategoryLabel key={label} variant={label} noText />)}
            </div>
        </Paper>
    );
};

DebtCard.defaultProps = {
    photo: Placeholderavatar,
    timestamp: '--/--/-- --:--',
    labels: [],
    returned: [],
};

export default DebtCard;
