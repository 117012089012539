// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { withFirebase, WithFirebaseProps } from 'react-redux-firebase';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Typography } from '@material-ui/core';
import { AuthButton } from 'styles/Button';

const Login = ({
    firebase: { login },
    enqueueSnackbar,
}: WithSnackbarProps &
    WithFirebaseProps<{}> & {
        enqueueSnackbar: (message: string) => void;
    }) => {
    const signupByGoogle = () => {
        login({
            provider: 'google',
            type: 'redirect',
        }).catch((ex: Error) => {
            if (!ex.message) return;
            enqueueSnackbar(ex.message);
        });
    };

    return (
        <>
            <Typography variant="h3">Welcome back</Typography>
            <AuthButton variant="outlined" onClick={signupByGoogle} fullWidth>
                Continue with Google
            </AuthButton>
            <AuthButton variant="outlined" fullWidth>
                Continue with Facebook
            </AuthButton>
            {/* TODO: find an actual solution */}
            {/* 
            // @ts-ignore */}
            <AuthButton component={Link} to="/auth/login/email" variant="outlined" fullWidth>
                Continue with your Email
            </AuthButton>
        </>
    );
};

export default withSnackbar(withFirebase(Login));
