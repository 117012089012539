// @flow

import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import PlaceholderAvatar from 'assets/images/avatar.svg';

const ProfileCard = ({
    photo,
    name,
    surname,
    email,
}: {
    photo: string;
    name: string;
    surname: string;
    email: string;
}) => {
    return (
        <div className="ProfileCard">
            <Avatar src={photo} alt="You" className="ProfileCard__photo" />
            <div className="ProfileCard__body">
                <Typography>
                    <strong>{`${surname} ${name}`}</strong>
                </Typography>
                <Typography>
                    <small>{email}</small>
                </Typography>
            </div>
        </div>
    );
};

ProfileCard.defaultProps = {
    photo: PlaceholderAvatar,
    name: '',
    surname: '',
    email: '',
};

export default ProfileCard;
