import React, { ChangeEvent } from 'react';
import { TextField, Typography } from '@material-ui/core';

const Password = ({
    value,
    onChange,
}: {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <>
            <Typography variant="h4">Almost there James!</Typography>
            <TextField
                value={value}
                onChange={onChange}
                variant="outlined"
                type="password"
                label="Password"
                fullWidth
            />
        </>
    );
};

export default Password;
