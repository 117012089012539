import styled from 'styled-components';
import { Button, Fab } from '@material-ui/core';

export const AuthButton = styled(Button)`
    && {
        margin-top: 16px;
        color: ${({ theme }) => theme.palette.primary.main};
        height: 48px;
    }
`;

export const FixedFab = styled(Fab)`
    position: fixed;
    bottom: 16px;
    right: 16px;
`;
