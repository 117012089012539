import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withFirebase, WithFirebaseProps } from 'react-redux-firebase';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { AppState } from 'config/reducer';
import { User } from 'types/database';
import Email from './components/Email';
import Password from './components/Password';
import Nav from './components/Nav';
import { BottomNavWizard } from './styles';

const errorStrings = {
    'auth/wrong-password': "The password you inserted doesn't seem right, retry",
    'auth/user-not-found': "This email doesn' correspond to any user. Try another.",
};

const getErrorMessage = (code: 'auth/wrong-password' | 'auth/user-not-found') => errorStrings[code];

const EmailLogin = ({
    authError,
    firebase: { login },
    enqueueSnackbar,
}: WithFirebaseProps<User> &
    WithSnackbarProps & {
        firebase: AppState['firebase'];
        authError: Error;
    }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // const progressValidator = (step: number) => {
    //     if (step === 1) {
    // login({
    //     email,
    //     password: '',
    // });
    //     }
    //     return false;
    // };

    const onSubmit = () => {
        login({
            email,
            password,
        }).catch(({ code }) => {
            enqueueSnackbar(getErrorMessage(code));
        });
    };

    return (
        <>
            <BottomNavWizard nav={<Nav onSubmit={onSubmit} />}>
                <Email value={email} onChange={event => setEmail(event.target.value)} />
                <Password value={password} onChange={event => setPassword(event.target.value)} />
            </BottomNavWizard>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    authError: state.firebase.authError,
});

export default connect(mapStateToProps)(withSnackbar(withFirebase(EmailLogin)));
