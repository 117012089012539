// @flow

import React from 'react';
import classNames from 'classnames';
import Currencies from './currencies.json';
import './Value.scss';

interface Props {
    currency: 'EUR' | 'USD';
    value: string;
    isHuge?: boolean;
    isBig?: boolean;
    isTiny?: boolean;
}

const Value = ({ currency, value, isHuge, isBig, isTiny }: Props) => {
    const splitValue = value.split('.');

    return (
        <span
            className={classNames(
                'Value',
                { 'Value--huge': isHuge },
                { 'Value--big': isBig },
                { 'Value--tiny': isTiny },
            )}
        >
            {Currencies[currency]}
            <strong>{splitValue[0]}</strong>
            {(isHuge || isBig) && <span>{splitValue[1]}</span>}
        </span>
    );
};

Value.defaultProps = {
    isHuge: false,
    isBig: false,
    isTiny: false,
};

export default Value;
