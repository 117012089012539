import React from 'react';
import { StepProps } from 'react-step-wizard';
import { Fab } from '@material-ui/core';
import NextIcon from '@material-ui/icons/ChevronRight';

const Nav = ({
    progressValidator,
    currentStep,
    totalSteps,
    nextStep,
    onSubmit,
}: StepProps & { progressValidator?: (step: number) => boolean; onSubmit: () => void }) => {
    // TODO: find way to remove useless validator
    if (typeof totalSteps !== 'number' || typeof currentStep !== 'number' || !nextStep) return null;

    const onClick = async () => {
        const canProgress = progressValidator ? progressValidator(currentStep) : true;
        if (canProgress) {
            if (currentStep < totalSteps) nextStep();
            else onSubmit();
        }
    };

    return (
        <nav>
            <Fab onClick={onClick} color="primary">
                <NextIcon />
            </Fab>
        </nav>
    );
};

export default Nav;
