import React, { useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { withFirestore, WithFirestoreProps } from 'react-redux-firebase';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Grid, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DialogToolbar from 'components/DialogToolbar/DialogToolbar';
import Calculator from 'components/Calculator';
import { ReactComponent as UOIcon } from 'assets/icons/uo.svg';
import { AppState } from 'config/reducer';
import { FixedFab } from 'styles/Button';
import Value from '../../components/Value/Value';
import { CustomContainer, Header, FieldGroup, Field } from './styles';

const NewDebtComponent = (
    props: RouteComponentProps &
        WithSnackbarProps &
        WithFirestoreProps & {
            auth: AppState['firebase']['auth'];
            type: 'IO' | 'UO';
        },
) => {
    const [value, setValue] = useState<null | number>(null);
    const [contractor, setUser] = useState('');
    const [name, setName] = useState('');
    const [expression, setExpression] = useState('');

    const {
        auth,
        firestore: { add },
        history,
        enqueueSnackbar,
        type,
    } = props;

    console.log(props);

    const validateBeforeSubmission = (val?: number | null, con?: string, n?: string) =>
        auth && auth.uid && val !== null && val !== undefined && val > 0 && con && n;

    const parseBeforeSubmission = (val: number, con: string, n: string) => ({
        owner: auth.uid,
        creditor: type === 'IO' ? con : auth.uid,
        debtor: type === 'IO' ? auth.uid : con,
        value: val,
        contractor: con.toLowerCase(),
        name: n,
        type,
        timestamp: new Date(),
    });

    const onChange = (setter: (value: any) => void) => (event: ChangeEvent<HTMLInputElement>) =>
        setter(event.target.value);
    // TODO: Assertions are ugly m8
    const onSubmit = () => {
        console.log(value, contractor, name);
        console.log(parseBeforeSubmission(value as number, contractor, name));
        validateBeforeSubmission(value, contractor, name)
            ? add('debts', parseBeforeSubmission(value as number, contractor, name)).then(() => {
                  enqueueSnackbar('Your debt is now being tracked!');
                  history.goBack();
              })
            : enqueueSnackbar(
                  "Something's wrong with your debt! Check weather you filled out all the details and if your internet connection is working",
              );
    };

    return (
        <div className="NewDebt">
            <DialogToolbar onClose={() => history.goBack()} />
            {value ? (
                <CustomContainer>
                    <Grid container justify="center">
                        <Grid item xs={12} md={6}>
                            <Header
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {
                                    setExpression(`${value}`);
                                    setValue(null);
                                }}
                                onClick={() => {
                                    setExpression(`${value}`);
                                    setValue(null);
                                }}
                            >
                                <UOIcon />
                                <Value currency="EUR" value={value.toFixed(2)} isHuge />
                            </Header>
                            <FieldGroup>
                                <Typography variant="h5">
                                    {type === 'IO' ? 'to' : 'from'}
                                </Typography>
                                <Field
                                    value={contractor}
                                    onChange={onChange(setUser)}
                                    variant="outlined"
                                    fullWidth
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Typography variant="h5">on</Typography>
                                <Field
                                    value={name}
                                    onChange={onChange(setName)}
                                    variant="outlined"
                                    fullWidth
                                />
                            </FieldGroup>
                        </Grid>
                    </Grid>
                    <FixedFab className="NewDebt__fab" color="primary" onClick={onSubmit}>
                        <SaveIcon />
                    </FixedFab>
                </CustomContainer>
            ) : (
                <Calculator
                    expression={expression}
                    onChange={setExpression}
                    onSubmit={v => setValue(Number.parseFloat(v))}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    auth: state.firebase.auth,
});

export default withSnackbar(withRouter(withFirestore(connect(mapStateToProps)(NewDebtComponent))));
