import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import './AvatarGraph.scss';

interface PieProps {
    value: number;
    total: number;
}

const Pie = ({ value, total }: PieProps) => (
    <ResponsivePie
        data={[
            {
                id: 'value',
                value,
            },
            {
                id: 'empty',
                value: total - value,
            },
        ]}
        fill={[
            {
                match: {
                    id: 'value',
                },
                id: 'value',
            },
            {
                match: {
                    id: 'empty',
                },
                id: 'empty',
            },
        ]}
        defs={[
            {
                id: 'value',
                type: 'patternDots',
                background: '#00CAFF',
                color: '#00CAFF',
            },
            {
                id: 'empty',
                type: 'patternDots',
                background: '#deebf7',
                color: '#deebf7',
            },
        ]}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        innerRadius={0.85}
        padAngle={1}
        cornerRadius={3}
        colors={{ scheme: 'blues' }}
        // backgroundColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        enableSlicesLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate
        motionStiffness={90}
        motionDamping={15}
        isInteractive={false}
    />
);

interface Props {
    value: number;
    total: number;
    className?: string;
    children: JSX.Element;
}

const AvatarGraph = ({ value, total, className, children }: Props) => {
    return (
        <div className="AvatarGraph">
            <Pie value={value} total={total} />
            <div className={`AvatarGraph__content ${className}`}>{children}</div>
        </div>
    );
};

AvatarGraph.defaultProps = {
    className: '',
};

export default AvatarGraph;
