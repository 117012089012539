import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter,
    RouteComponentProps,
} from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';
import AppLayout from 'components/appLayout/AppLayout';
import AuthLayout from 'components/AuthLayout';
import Login from 'routes/login';
import EmailLogin from 'routes/loginEmail';
import Home from 'routes/home/HomeComponent';
import UO from 'routes/uo/HomeComponent';
import Settings from 'routes/settings';
import NewDebt from 'routes/newDebt/NewDebtComponent';

const Routes = withRouter((props: RouteComponentProps) => (
    <Switch>
        <Route path="/auth">
            <AuthLayout>
                <Switch>
                    <Redirect exact from="/auth" to="/auth/login" />
                    <Route exact path="/auth/login" component={Login} />
                    <Route exact path="/auth/login/email" component={EmailLogin} />
                </Switch>
            </AuthLayout>
        </Route>
        <Route path="/new">
            <Switch>
                <ProtectedRoute path="/new/io" component={NewDebt} type="IO" />
                <ProtectedRoute path="/new/uo" component={NewDebt} type="UO" />
            </Switch>
        </Route>
        <AppLayout navValue={props.location.pathname}>
            <Switch>
                <Redirect exact from="/" to="/io" />
                <ProtectedRoute exact path="/io" component={Home} />
                <ProtectedRoute path="/io/:id" component={Home} />
                <ProtectedRoute exact path="/uo" component={UO} />
                <ProtectedRoute path="/uo/:id" component={UO} />
                <ProtectedRoute exact path="/settings" component={Settings} />
            </Switch>
        </AppLayout>
    </Switch>
));

function App() {
    return (
        <Router>
            <Routes />
        </Router>
    );
}

export default App;
