import { combineReducers } from 'redux';
import * as reduxFirebase from 'react-redux-firebase';
import * as reduxFirestore from 'redux-firestore';
import { User, DatabaseDebt } from 'types/database';

const rootReducer = combineReducers({
    firebase: reduxFirebase.firebaseReducer,
    firestore: reduxFirestore.firestoreReducer,
});

export default rootReducer;
export interface AppState {
    firebase: reduxFirebase.FirebaseReducer.Reducer<User>;
    firestore: {
        data: {
            users: User[];
            debts: DatabaseDebt[];
        };
        add: (collection: 'string', data: {}) => Promise<void>;
    };
}
