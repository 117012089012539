// @flow

import React from 'react';
import { evaluate } from 'mathjs';
import { Grid, ButtonBase } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/BackspaceOutlined';
import TickIcon from '@material-ui/icons/Done';
import './Calculator.scss';
import classNames from 'classnames';

interface Props {
    expression: string;
    value?: number;
    onChange: (value: string) => void;
    onSubmit: (value: string) => void;
}

const Calculator = ({ expression, value, onChange, onSubmit }: Props) => {
    const functions: { [s: string]: () => void } = {
        del: () => onChange(expression.slice(0, expression.length - 1)),
        '=': () => onChange(`${evaluate(expression)}`),
        submit: () => onSubmit(evaluate(expression)),
    };

    const symbols: { [s: string]: JSX.Element | string } = {
        '(': '(',
        ')': ')',
        '%': '%',
        del: <BackspaceIcon />,
        '/': '÷',
        '*': '×',
        '-': '-',
        '+': '+',
        '.': ',',
        '=': '=',
        submit: <TickIcon />,
    };

    const buttons = [
        '(',
        ')',
        'del',
        '/',
        7,
        8,
        9,
        '*',
        4,
        5,
        6,
        '-',
        1,
        2,
        3,
        '+',
        '.',
        0,
        '=',
        'submit',
    ];

    const getLastUnsafeIndex = (s: string): number => {
        let lastUnsafeIndex = -1;
        ['(', '/', '*', '-', '+'].forEach(unsafeSymbol => {
            if (s.lastIndexOf(unsafeSymbol) > lastUnsafeIndex)
                lastUnsafeIndex = s.lastIndexOf(unsafeSymbol);
        });
        return lastUnsafeIndex;
    };

    const removeOpenBrackets = (s: string): string =>
        s.lastIndexOf('(') > s.lastIndexOf(')') ? s.slice(0, s.lastIndexOf('(') - 1) : s;

    const isLastCharUnsafe = (s: string): boolean => getLastUnsafeIndex(s) === s.length - 1;

    const onNumberPress = (number: string | number) => () => {
        const newExpression =
            isLastCharUnsafe(expression) && typeof number === 'string'
                ? `${expression.slice(0, getLastUnsafeIndex(expression))}${number}`
                : `${expression}${number}`;

        onChange(newExpression);
    };

    const unbracketedExpression = removeOpenBrackets(expression);

    const safeExpression = isLastCharUnsafe(unbracketedExpression)
        ? unbracketedExpression.slice(0, getLastUnsafeIndex(unbracketedExpression))
        : unbracketedExpression;

    const displayValue =
        expression.slice(getLastUnsafeIndex(expression) + 1, expression.length) ||
        evaluate(safeExpression);

    const getSymbol = (key: string): JSX.Element | string => symbols[key];
    const getFunction = (key: string | number): (() => void) => functions[key];

    return (
        <div className="Calculator">
            <div className="Calculator__expression">{expression}</div>
            <div className="Calculator__value">{displayValue || 0}</div>
            <div className="Calculator__body">
                <Grid container>
                    {buttons.map(buttonValue => (
                        <Grid key={buttonValue} className="Calculator__button__wrapper" item xs={3}>
                            <ButtonBase
                                className={classNames(
                                    'Calculator__button',
                                    {
                                        'Calculator__button--action':
                                            typeof buttonValue === 'string' && buttonValue !== '.',
                                    },
                                    {
                                        'Calculator__button--submit': buttonValue === 'submit',
                                    },
                                )}
                                onClick={
                                    getFunction(buttonValue)
                                        ? getFunction(buttonValue)
                                        : onNumberPress(buttonValue)
                                }
                            >
                                {typeof buttonValue === 'string'
                                    ? getSymbol(buttonValue)
                                    : buttonValue}
                            </ButtonBase>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default Calculator;
