import styled from 'styled-components';

interface CursorProps {
    position: number;
}

export const TabsCursor = styled.div<CursorProps>`
    height: 2px;
    width: 24px;
    background-color: ${props => props.theme.palette.primary.main};
    position: absolute;
    top: calc(50% + 16px);
    left: ${({ position }) => position + 16}px;
    transform: translate(-50%, -50%);
    transition: 0.2s;
`;

interface TabsProps {
    solid?: boolean;
}

export const Tabs = styled.div<TabsProps>`
    display: flex;
    font-weight: 600;
    position: relative;
    background-color: ${({ solid }) => solid && '#fafafa'};
    margin: 0 -16px;
    margin-bottom: 12px;
    padding: 0 16px;
    box-shadow: ${({ solid }) => solid && '0 4px 32px -12px rgba(0, 0, 0, 0.2)'};
    border-radius: 0 0 24px 24px;
`;
