// @flow

import React, { useState } from 'react';
import { TabsCursor, Tabs } from './style';

interface Props {
    value: number;
    onChange: (value: number) => void;
    solid?: boolean;
    children: JSX.Element[];
}

const PlainTabs = ({ value, onChange, solid, children, ...rest }: Props) => {
    const [tabWidths, setTabWidths] = useState<number[]>([]);
    const cursorPosition = tabWidths.reduce((acc, curr, index) => {
        if (index < value) return acc + curr;
        if (index === value) return acc + curr / 2;
        return acc;
    }, 0);

    return (
        <Tabs solid={solid}>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    key: child.props.label,
                    isSelected: index === value,
                    onClick: () => onChange(index),
                    onResize: ({ width }: { width: number }) =>
                        setTabWidths(Object.assign([], tabWidths, { [index]: width })),
                }),
            )}
            {cursorPosition && <TabsCursor position={cursorPosition} />}
        </Tabs>
    );
};

export default PlainTabs;
