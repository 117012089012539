// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AppState } from 'config/reducer';

interface ProtectedRouteProps extends RouteProps {
    component: React.ComponentType;
    auth: AppState['firebase']['auth'];
    [x: string]: any;
}

const ProtectedRoute = ({
    path,
    match,
    component: Component,
    auth: { isLoaded, isEmpty },
    ...rest
}: ProtectedRouteProps) => {
    console.log(rest);
    const ShowOrRedirect = () =>
        isEmpty ? (
            <Redirect to="/auth" />
        ) : (
                <Route path={path} render={routeProps => <Component {...routeProps} {...rest} />} />
        );

    return isLoaded ? <ShowOrRedirect /> : null;
};

const mapStateToProps = (state: AppState) => ({
    auth: state.firebase.auth,
});

export default connect(mapStateToProps)(ProtectedRoute);
