import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

declare module '@material-ui/core/styles/createTypography' {
    interface Typography {
        h4: {
            fontWeight: React.CSSProperties['fontWeight'];
            fontSize: React.CSSProperties['fontSize'];
            lineHeight: React.CSSProperties['lineHeight'];
            color: React.CSSProperties['color'];
        };
        button: {
            textTransform: React.CSSProperties['textTransform'];
            fontWeight: React.CSSProperties['fontWeight'];
        };
    }

    interface TypographyOptions {
        h4?: {
            fontWeight?: React.CSSProperties['fontWeight'];
            fontSize?: React.CSSProperties['fontSize'];
            lineHeight?: React.CSSProperties['lineHeight'];
            color?: React.CSSProperties['color'];
        };
        button?: {
            textTransform?: React.CSSProperties['textTransform'];
            fontWeight?: React.CSSProperties['fontWeight'];
        };
    }
}

// HACK: just to continuie, this is not how it should be though.
const capitalize = (): 'capitalize' => 'capitalize';

const baseTheme = {
    palette: {
        primary: {
            light: '#6aa0ff',
            main: '#0072ff',
            dark: '#0048cb',
            contrastText: '#fff',
        },
        secondary: {
            light: '#6bfdff',
            main: '#00caff',
            dark: '#0099cc',
            contrastText: '#000',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Montserrat',
        h3: {
            fontWeight: 700,
            fontSize: '2rem',
            lineHeight: '2.6rem',
            color: '#333333',
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: '2rem',
            color: '#333333',
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.4rem',
            lineHeight: '1.8rem',
            color: '#333333',
        },
        button: {
            // HACK: just to continuie, this is not how it should be though.
            textTransform: capitalize(),
            fontWeight: 700,
        },
    },
    shape: {
        borderRadius: 12,
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 20,
            },
            elevation1: {
                boxShadow: '0px 2px 16px -12px rgba(0, 0, 0, 0.1)',
            },
        },
    },
};

const xs = {
    ...baseTheme,
};
const sm = {
    ...xs,
};
const md = {
    ...sm,
    typography: {
        useNextVariants: true,
        fontFamily: 'Montserrat',
        h4: {
            fontWeight: 700,
            fontSize: '2rem',
            lineHeight: '2.6rem',
            color: '#333333',
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.6rem',
            lineHeight: '2rem',
            color: '#333333',
        },
        button: {
            // HACK: just to continuie, this is not how it should be though.
            textTransform: capitalize(),
            fontWeight: 700,
        },
    },
};
const lg = {
    ...md,
};
const xl = {
    ...lg,
};

const themes = {
    xs,
    sm,
    md,
    lg,
    xl,
};

function getTheme(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
    const selectTheme = (bp: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): ThemeOptions => themes[bp];
    console.log(selectTheme(breakpoint));

    return createMuiTheme(selectTheme(breakpoint));
}

export default getTheme;
