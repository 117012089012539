import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import Dial from 'components/Dial/Dial';
import Navbar from 'components/Navbar/Navbar';
import BottomNav from 'components/bottomNav/BottomNav';
import './AppLayout.scss';
import { StickyContainer } from 'react-sticky';

const DialActions = [
    {
        icon: <ArrowLeftIcon style={{ transform: 'rotate(-45deg)' }} />,
        name: 'Take',
        to: '/new/io',
    },
    {
        icon: <ArrowRightIcon style={{ transform: 'rotate(-45deg)' }} />,
        name: 'Give',
        to: '/new/uo',
    },
    { icon: <AddIcon />, name: 'Return', to: '/new/return' },
];

interface Props {
    navValue: string;
    children: JSX.Element;
}

const AppLayout = ({ navValue, children }: Props) => {
    const navValueRoot = `/${navValue.split('/')[1]}`;
    if (
        navValueRoot !== '/' &&
        navValueRoot !== '/io' &&
        navValueRoot !== '/uo' &&
        navValueRoot !== '/settings'
    )
        return null;

    return (
        <div className="AppLayout">
            <Navbar />
            <StickyContainer className="AppLayout__body">{children}</StickyContainer>
            <BottomNav value={navValueRoot} />
            <Dial actions={DialActions} />
        </div>
    );
};

export default AppLayout;
